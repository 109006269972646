export const environment = {
  production: false,                                        // Declearing this constants are only for local version
  contract: '0x5d9057a2561a11d87FA1f2cE09CF064C6d8c5BBe',   // Native token contract address
  company_id: 'kaAlQ8GandXGcF3ES6emW7Zi2rk2',               // AUDIT Software generated ID. (Optional can be removed)
  bsc_api: 'https://api.etherscan.io/api?',                  // Blockchain provider API Url
  bsc_key:'IJN6TQABX2MPM18YQGXNH8EAKHGKH4CZZM',             // API KEY for Bsc or any other chain which provides it (We do not use it for now)
  bonds: '0x01D6601168d3CffF88E221328D0A1948b00547Cd',      // Native BOND  Contract Address  
  lpBond: '0x01D6601168d3CffF88E221328D0A1948b00547Cd',     // LP BOND  Contract Address
  staking: '0x5B6AC372b14c0e04514941546F81309EbaD25d2F',    // Token Staking Contract Address
  lpStaking: '0xE7Ea52BBEc95160648734990Bfae51AB9e43bC26',  // LP Staking Contract Address
  USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',       // USDC Contract address
  NATIVE: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',     // NATIVE POOL WRAPPED CURRENCY ex: WBNB, WETH etc...
  ChainID: 1,                                              // NATIVE CHAIN ID ex: 1 for ETH, 56 for BSC etc...
  RPC: 'https://ethereum-rpc.publicnode.com',                // RPC connector to its chain
  DEX: 'https://app.uniswap.org/add/v2/ETH',           // SWAPER URL
};
